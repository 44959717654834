<template>
  <sub-page
      :title="$t('DebtList')"
  >
    <v-sheet class="d-flex wsRoundedLight pa-3 mb-5" :style="`border : 1px solid ${wsBACKGROUND}`">

      <h1>Total : </h1>

    </v-sheet>

    <ws-data-table
        :headers="headers"
        :items="items"
    >

    </ws-data-table>
  </sub-page>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "newClients",
  data() {
    return {
      items : [],
      date : null,
      years : [],
      months : [],
      selectedYear : "2023",
      selectedMonth : 1
    }
  },
  computed : {
    headers() {
      return [
        { text : this.$t('Name') , value : 'name' },
        { text : this.$t('Plan') , value : 'plan' },
        { text : this.$t('PlanPrice') , value : 'plan_price' },
        { text : this.$t('ActiveTill') , value : 'active_till' },
        { text : this.$t('Sum') , value : 'payment_total' },
      ]
    }
  },
  methods : {
    ...mapActions('adminPayments', [
      'GET_DEBT_BUSINESSES',
    ]),

    async initPage() {
      let result = await this.GET_DEBT_BUSINESSES()
      if ( !result ) {
        return this.ERROR()
      }
      this.items = result.items
    }
  },
  watch : {
  },
  mounted() {
    this.initPage()
  }
}
</script>

<style scoped>

</style>